import moment from 'moment';
import 'slick-carousel';

jQuery( document ).ready(function($) {
    $.ajax( {
        url: 'https://rest.bandsintown.com/artists/Freddie Dredd/events?app_id=45PRESS_fdredd',
        method: 'GET',
        dataType: 'json',
        xhrFields: {
            withCredentials: false
        },
        error: () => {
        },
        success: data => {
            const events = $( '#events' );
            let html = '';
            if ( data.length ) {
                for ( let event of data ) {
                    const event_location = event.venue.region ? event.venue.city + ', ' + event.venue.region : event.venue.city + ', ' + event.venue.country;
                    html += '<div class="event" data-bit-id="' + event.id + '">';
                    html += '<div class="event-date">' + moment( event.datetime ).format( 'MMMM DD' ) + '</div>';
                    html += '<div class="event-location">' + event_location + '</div>';
                    html += '<div class="event-venue">' + event.venue.name + '</div>';
                    if (event.offers.length){
                        html += '<a href="' + event.offers[0].url + '" target="_blank" class="btn btn-white">Tickets</a>';
                    } else{
                        html += '<a href="' + event.url + '" target="_blank" class="btn btn-white">Tickets</a>';
                    }
                    html += '</div>';
                }
                events.html( html );
            } else {
                events.html( '<p class="noevent">No upcoming events.</p>' );
            }
        }
    });

    $('.newsletter').on('submit', function(e) {
        e.preventDefault();
        const DATA = $(this).serialize();

        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            dataType: 'json',
            data: DATA,
            xhrFields: {
                withCredentials: false,
            },
            success: function (data) {
                $('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
            },
            error: function (err) {
                console.log(err);
            }
        });
    });

    $('#video-slider').slick({
        prevArrow: '<button type="button" class="slick-prev"><img src="./dist/img/Arrow.svg" alt="prev"></button>',
        nextArrow: '<button type="button" class="slick-next"><img src="./dist/img/Arrow.svg" alt="next"></button>',
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        appendArrows: '.video-arrows',
        responsive:[
            {
                breakpoint: 800,
                settings:{
                    slidesToShow: 1
                }
            }
        ]
    });

});